:root {
    --bg-color: #1a2427;
    --accent-color: #0ff7e8;
    --text-color: #ffffff;
    --secondary-color: #11181a;
}

::-webkit-scrollbar {
  width: 0.5rem;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #26363b;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: var(--bg-color);
    color: var(--text-color);
    min-height: 100vh;
    overflow-x: hidden;
    position: relative;
    padding-top: 5rem;
}

#network-canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}


.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000; /* Ensure it floats above other content */
    background-color: rgba(26, 36, 39, 0.9); /* Slight transparency */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 1rem 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  
  .logo-symbol img {
      height: 3rem;
    }
    
    nav ul {
        display: flex;
        gap: 2rem;
        
        li a {
            text-decoration: none;
            color: white;
            font-size: 1.5rem;
            transition: color 0.3s ease;
            cursor: pointer;
            
            &:hover {
                color: #00ffff; /* Cyan hover effect */
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 800px) {
    .navbar {
        height: min-content;
        padding: 0.8rem 1rem;
        nav ul {
            li a {
                font-size: 1.4rem;
            }
        }
    }
}

@media (max-width: 600px) {
    .navbar {
        nav ul {
            li a {
                font-size: 1.2rem;
            }
        }
    }
}

header {
    padding: 20px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 10;
}

.logo {
    font-size: 24px;
    font-weight: bold;
    color: var(--accent-color);
    text-decoration: none;
    transition: transform 0.3s ease;
    display: flex;
    align-items: center;

    &:hover {
        transform: scale(1.05);
    }
}

.logo-symbol {
    position: relative;
    width: 40px;
    height: 40px;
    margin-right: 10px;

    &::before, &::after {
        position: absolute;
        background-color: var(--accent-color);
    }

    &::before {
        width: 60%;
        height: 2px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
        width: 2px;
        height: 60%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
    }

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
    }
}

nav ul {
    list-style: none;
    display: flex;
    gap: 30px;

    li a {
        color: var(--text-color);
        text-decoration: none;
        font-size: 16px;
        position: relative;
        padding: 5px 0;
        transition: color 0.3s ease;

        &:hover {
            color: var(--accent-color);
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 1px;
            background-color: var(--accent-color);
            transition: width 0.3s ease;
        }

        &:hover::after {
            width: 100%;
        }
    }
}

.hero-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 30vh;
  min-width: 100%;
  margin-top: 10rem;

  .hero {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1; /* Allow hero section to take equal space */
    margin-right: 2rem;
    padding-left: 5vw;

    h1 {
      font-size: 5rem; /* Default size */
      margin-bottom: 10px;
      color: var(--text-color);
    }

    h2 {
      font-size: 36px; /* Default size */
      margin-bottom: 20px;
      color: var(--accent-color);
    }

    p {
      font-size: 18px; /* Default size */
      color: var(--text-color);
      width: 80%;
    }
  }

  .social-links {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    flex: 1;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      font-size: 2rem; /* Default size */
      text-decoration: none;
      color: white;
      transition: color 0.3s ease;

      &:hover {
        color: #00ffff; /* Cyan hover effect */
        transform: translateX(-5px);
        transform: scale(1.1) translateX(-5px);
        transition: transform 0.3s ease;
        transition: color 0.3s ease;
        
      }
    }
  }
}

@media (max-width: 800px) {
  .hero-container {
    margin-top: 5rem;
    flex-direction: column; /* Stack hero and social links vertically */
    align-items: center;

    .hero {
      flex-direction: column;
      align-items: center;
      text-align: center; /* Center-align text */
      margin-right: 0;
      padding-left: 0;
      h1 {
        font-size: 36px; /* Scale down */
      }

      h2 {
        font-size: 28px; /* Scale down */
      }

      p {
        font-size: 16px; /* Scale down */
      }
    }

    .social-links {
      flex-direction: row; /* Arrange social links horizontally */
      gap: 1rem;
      margin-top: 2rem;
      a {
        font-size: 1.25rem; /* Scale down */
      }
    }
  }
}

@media (max-width: 600px) {
  .hero-container {
    .hero {
      padding-left: 0;
      h1 {
        font-size: 28px; /* Further scale down */
      }

      h2 {
        font-size: 22px; /* Further scale down */
      }

      p {
        font-size: 14px; /* Further scale down */
      }
    }

    .social-links {
      gap: 1rem;
      margin-top: 1rem;
      a {
        font-size: 1rem; /* Further scale down */
      }
    }
  }
}
.projects {
    padding: 100px 50px 50px;
    position: relative;
    z-index: 10;
}

.section-header {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 50px;
    padding-bottom: 10px;
    position: relative;
    display: inline-block;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100px;
        height: 3px;
        background-color: var(--accent-color);
    }
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 30px;
}

@media (max-width: 768px) {

  .projects {
    padding: 50px 20px 20px;
  }

  .projects-grid {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

.project-card {
    background-color: var(--secondary-color);
    border-radius: 5px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    z-index: 1;
    padding: 25px;
    border-left: 3px solid transparent;

    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3), 0 0 10px var(--accent-color);
        border-left: 3px solid var(--accent-color);
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: var(--accent-color);
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 0.3s ease;
        z-index: 2;
    }

    &:hover::before {
        transform: scaleX(1);
    }
}

.project-card .tech-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 15px;
}

.tech-tag {
    background-color: rgba(15, 247, 232, 0.1);
    color: var(--accent-color);
    padding: 3px 8px;
    border-radius: 3px;
    font-size: 12px;
}

.project-content h3 {
    font-size: 22px;
    margin-bottom: 10px;
    color: var(--accent-color);
}

.project-content p {
    opacity: 0.8;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 1.5;
    flex: 1;
}

.project-links {
    display: flex;
    gap: 15px;
    padding-bottom: 10px;
    margin-top: auto;
}

.project-links a {
    color: var(--accent-color);
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
    border: 1px solid var(--accent-color);
    border-radius: 3px;
    transition: all 0.3s ease;

    &:hover {
        background-color: var(--accent-color);
        color: var(--bg-color);
    }
}

.about {
  padding: 100px 50px 50px;
  position: relative;
  z-index: 10;
}

.about-grid, .contact-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 40px;
}

.about-card, .contact-card {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: rgba(16, 24, 26, 0.8);
  border: 1px solid #00ffff;
  border-radius: 4px;
  margin: 5px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.about-card:hover, .contact-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 255, 255, 0.2);
}

.about-card h3, .contact-card h3 {
  color: #00ffff;
  margin-bottom: 15px;
  font-size: 1.5rem;
}

.about-card p, .contact-card p {
  color: #ffffff;
  margin-bottom: 15px;
  font-size: 1.3rem;
}

.about-card .tech-tags {
  display: flex;
  gap: 1rem;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 15px;
}

.skill-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #ffffff;
}

.contact {
  padding: 100px 50px 50px;
  position: relative;
  z-index: 10;
}

.contact-methods {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.contact-method {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s;
}

.contact-method:hover {
  color: #00ffff;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group input, .form-group textarea {
  width: 100%;
  padding: 12px;
  background-color: rgba(16, 24, 26, 0.6);
  border: 1px solid rgba(0, 255, 255, 0.3);
  border-radius: 4px;
  color: #ffffff;
  font-family: inherit;
  transition: border-color 0.3s;
}

.form-group input:focus, .form-group textarea:focus {
  outline: none;
  border-color: #00ffff;
}

.submit-btn {
  padding: 12px 20px;
  background-color: transparent;
  border: 1px solid #00ffff;
  border-radius: 4px;
  color: #00ffff;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.submit-btn:hover {
  background-color: #00ffff;
  color: #101820;
}

@media (max-width: 768px) {
  .about-grid, .contact-grid {
    grid-template-columns: 1fr;
  }
}

.experience-timeline {
  margin: 2rem 0;
  padding: 1rem;
  width: 100%;
  
  h3 {
    color: #0ff7e8;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }
}

.timeline {
  position: relative;
  padding-left: 2rem;
  
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background: linear-gradient(to bottom, rgba(0, 255, 255, 0.8), rgba(0, 255, 255, 0.2));
  }
}

.timeline-item {
  position: relative;
  margin-bottom: 2.5rem;
  
  &:last-child {
    margin-bottom: 0;
  }
}

.timeline-dot {
  position: absolute;
  left: -2.55rem;
  top: 1rem;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #00ffff;
  box-shadow: 0 0 8px rgba(0, 255, 255, 0.8);
}

.timeline-content {
  font-size: 1.25rem;
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid #00ffff;
  border-radius: 8px;
  padding: 1.25rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 255, 255, 0.2);
  }

  .tech-tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 0.5rem;
  }

  p {
    width: 80%;
    margin: 0.5rem 0;
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.5;
  }
}

.timeline-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
  
  h4 {
    color: #0ff7e8;
    margin: 0;
    font-size: 1.2rem;
  }
}

.timeline-date {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
}

.timeline-company {
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  margin-bottom: 0.75rem;
}

// Responsive adjustments
@media (max-width: 768px) {
  .timeline-header {
    flex-direction: column;
    
    h4 {
      margin-bottom: 0.5rem;
    }
  }
}